/* .container .row>[class^="col"] {
    padding-top: .75rem;
    padding-bottom: .75rem;
    background-color: #E5EDF5;
    border: 1px solid #C9C1D5;
    color: #5F5F5F;
} */

html {
    scroll-behavior: smooth;
}

html, body {
    height: 99%;
}

body {
    background-image: url(/TarantulaNebulaLarge.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color: black;

}

.tryDesigner {
    margin-top: 0px;
    margin-left: 0;
    position: relative;
    right: 24px;
    width: 15rem;
    /* position: relative;
    top: 120px; */
}

.tryDesigner2 {
    margin-top: 0px;
    margin-left: 0;
    position: relative;
    right: 24px;
    /* position: relative;
    top: 120px; */
}

.tryDesigner2margin {
    margin-bottom: 10px;
}

.homeSplash {
    margin: 0;
    padding: 0;
    position: relative;
    left: 19px;
    top: -10px;
    font-family: GoboldExtra1Italic;
    font-size: 3rem;
    float: right;
    color: white;
    text-shadow: 2px 2px black;
}

.homeSplashSubtitle {
    font-family: GoboldExtra1Italic;
    font-size: 2rem;
    float: right;
    color: white;
    text-shadow: 2px 2px black;
}

.homeVersionNumber {
    clear: both;
    font-family: GoboldExtra1Italic;
    font-size: 1rem;
    float: right;
    color: white;
    text-shadow: 2px 2px black;
}

.homeSplashSmall {
    font-size: 2rem;
}

.small {
    font-size: smaller;
}

.right {
    text-align: right;
}

.footerLink {
    background: black;
    padding: 5px;
    border-radius: 5px;
}

.footerText {
    /* text-shadow: 5px 5px 5px red */
}

p.homeIntro {
    background-color: white;
    padding: 10px;
    border-radius: 4.8px;
    opacity: 70%;
}

.main, nav {
    box-shadow: 3px 3px 3px #8a0803 !important;
}

div.chargenSection {
    margin-bottom: 1.5rem !important;
    /* padding-bottom: 0.5rem !important; */
}

div.chargenSection h2 {
    border-bottom: 2px solid black;
    margin-bottom: 1rem;
}

div.chargenSection h3 {
    border-bottom: 1px solid black;
    margin-bottom: 0.5rem;
}

.ql-editor p {
    margin-bottom: 10px !important;
}

@font-face {
    font-family: DreamMMA;
    src: url(/DreamMMA.ttf);
}

@font-face {
    font-family: GoboldExtra1Italic;
    src: url(/GoboldExtra1Italic.otf);
}

@font-face {
    font-family: GoboldRegular;
    src: url(/GoboldRegular.otf);
}

.navbar .freebooter {
    font-family: DreamMMA !important;
    font-size: 1.75rem !important;
    padding: 0;
    margin: 0;
    vertical-align: middle;
}

.navbar a {
    font-family: GoboldExtra1Italic !important;
    font-style: italic;
}

a.btn, button, select {
    font-family: GoboldRegular !important;
}

.freebooterImg {
    height: 2rem;
    width: 2rem;
    padding: 0;
    margin: 0;
    vertical-align: middle;
}

.freebooterImgSmall {
    height: 1rem;
    width: 1rem;
}

.main {
    background-color: white;
}

.mainMenu {
    font-family: Copperplate, "Copperplate Gothic Light", fantasy;
}

/* .freebooter {
    font-family: Copperplate, "Copperplate Gothic Light", fantasy;
    font-weight: bold;
} */

h1, h2, h3, h4, h5, h6 {
    font-family: GoboldExtra1Italic, Copperplate, "Copperplate Gothic Light", fantasy;
}

h1 {
    font-size: 1.75rem !important;
}

h2 {
    font-size: 1.5rem !important;
}

h3 {
    font-size: 1.25rem !important;
    /* text-decoration: underline; */
    text-decoration-color: lightgray;
    margin-top: 0 !important;
}

h4 {
    font-size: 1rem !important;
}

th.w-10 {
    width: 10% !important;
}

td.w-10 {
    width: 10% !important;
}

.card-header h2 {
    margin-bottom: 0 !important;
}

.loadingSpinner {
    position: fixed;
    width: 100px;
    height: 100px;
    font-size: 200px;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    opacity: 0.3;
}

a:focus-visible {
    outline: 2px dashed #007bff !important;
    /* border: 1px solid orange;  */
}

a.nav-link:hover {
    color: black;
    text-decoration: underline;
    text-decoration-color: rgba(0,0,0,.3);
}

a.navbar-brand:hover {
    text-decoration: underline;
    text-decoration-color: rgba(0,0,0,.3);
}

table.attributeTable th {
    padding: 5px;
}

table.attributeTable td {
    padding: 5px;
}

table.attributeTable select {
   width: 50px;
}

.disabled {
    cursor: not-allowed !important;
}

button.btn-tiny, a.btn-tiny {
    padding: 1px 4px;
    margin: 0px 4px 4px 0px;
    font-size: 0.75rem;
}

button.btn-skill {
    width: 7rem;
}

button.btn-level {
    width: 6rem;
}

button.btn-attribute {
    width: 9.5rem;
}

button.btn-pickSkill {
    width: 5rem;
}

input[type=checkbox].lgCheckbox {
    width: 18px;
    height: 18px;
    position: relative;
    top: 3px;
}

ul {
    padding-inline-start: 15px;
}

.card-header h5 {
    margin: 0;
}

span.title {
    font-weight: bold;
    font-style: italic;
}

div.psychicSkills p {
    text-indent: 1em;
}

div.psychicSkills p.noIndent {
    text-indent: 0em;
}

div.floatTop {
    position: fixed;
    top: 20px;
    right: 20px;
    border: 1px solid black;
    background-color:aquamarine;
    padding: 5px;
}

.itemName {
    font-weight: 800;
    color: black;
}

.swn {
    font-weight: bold;
    font-style: italic;
}

div.charSheetTerse {
    border: 1px solid grey;
    width: 800px;
}

h5.faqHeader {
    font-size: 1rem !important;
    margin-top: 1rem;
    border-bottom: 2px solid black;
}

.container {
    height: 100%;
}

#root {
    height: 100%;
}

.pageBody {
    padding: 0.75rem 1.5rem !important;
    background-color: white; 
    min-height: 85% !important;
}

.pageBody.heroShot {
    background-color: transparent; 
}

div.valError {
    border: 1px solid red;
    background-color: rgb(253, 234, 234);
    padding: 4px 8px;
    color: black;
    border-radius: 8px;
    /* box-shadow: 1px 1px red; */
    display: inline-block;
    margin: 2px 0px;
}

body {
    background-image: url(/SpaceShipHero.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
}

.charMenuBtn {
    text-shadow: 1px 1px black;
}

.svg {
    filter: drop-shadow(1px 1px 1px rgb(0 0 0));
}

.sheet {
    width: 100%;
    min-height: 600px;
}

.privacyLink {
    position: relative;
    bottom: 10px;
    right: 10px;
    color: white;
}

.privacyLink:hover {
    color: white;
}

ul.noBullet {
    list-style-type:none
}

.green {
    color: green;
}

div.homeNote {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    margin: 20px 0;
    border: grey solid 1px;
    width: 100%; 
    margin-top: 100px;
    opacity: 0.90;
}

.levelUpImg {
    width: 20%; 
}

.floatRight {
    float: right; 
}

.collapsed {
    display: none;
}

.collapsible {
    cursor: grab;
}

.uncollapsed {
    display: block;
}

.smallClassAndLevel {
    display: none;
}

.alert-enter {
    opacity: 1;
    transform: scale(0.9);
}

.alert-enter-active {
    opacity: 0;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.alert-exit {
    opacity: 0;
}

.alert-exit-active {
    opacity: 1;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

.buyAction {
    border: 0;
    display: block;
    width: 100%;
    margin: 0 0  0;
    background-color: white;
    text-align: left;
}

.buyAction:hover {
    background-color: lightblue;
}

.dropdown-menu {
    min-width: 12rem !important; 
    width: 12rem !important;
    padding: 0;
    margin: 0;
}

.benefits .cbLarge {
    width: 1rem;
    height: 1rem;
    padding: 0;
    margin: 0;
    margin-right: 0.5rem;
    vertical-align: middle;
}

.benefits label {
    padding: 0;
    margin: 0;
    font-weight: bold;
    margin-right: 0.5rem;
}

.benefits svg {
    padding: 0;
    margin: 0;
    vertical-align: middle;
    margin-right: 0.5rem;
}

.tryButton {
    width: 17rem;
}

.floatLeft {
    float: left;
}

.smaller {
    font-size: smaller;
}

.alignRight {
    text-align: right;
}

.modal-dialog {
    max-width: 900px !important;
}

.statsTitle {
    display: inline-block;
    width: 5.5rem;
    background-color: red;;
}

input.shortNum {
    width: 4rem;
}

.red {
    color: rgb(250, 132, 132);
}

/* .numCol {
    width: 4rem;
    display: inline-block;
} */
  
@media only screen and (max-width: 1020px) {

    body {
        background-image: url(/Robot.jpg);
    }

    .homeSplash {
        font-size: 2.0rem;
    }

    .homeSplashSubtitle {
        font-size: 1.33rem;
    }

    .levelUpImg {
        width: 33%; 
    }

    div.homeNote {
        margin-top: 70px;
    }

    .smallClassAndLevel {
        display: none;
    }

}

@media only screen and (max-width: 609px) {

    .homeSplash {
        /* left: -19px; */
        font-size: 1.5rem;
        /* float: none; */
    }

    .homeSplashSubtitle {
        font-size: 1rem;
        /* float: none; */
    }

    .levelUpImg {
        width: 40%; 
    }

    div.homeNote {
        margin-top: 70px;
    }

    .smallClassAndLevel {
        display: none;
    }
    
    .modal-dialog {
        max-width: 90% !important;
    }
    
}

@media only screen and (max-width: 574px) {

    .smallClassAndLevel {
        display: none;
    } 
}

@media only screen and (max-width: 576px) {
    
    .homeSplash {
        font-size: 1.5rem;
        /* float: none; */
    }

    .homeSplashSubtitle {
        font-size: 1rem;
        /* float: none; */
    }

    .navbar .freebooter {
        font-family: DreamMMA !important;
        font-size: 1.5rem !important;
        padding: 0;
        margin: 0;
        vertical-align: middle;
    }

    .levelUpImg {
        width: 50%; 
    }

    div.homeNote {
        margin-top: 70px;
    } 

    .smallClassAndLevel {
        display: inline-block;
    }
    
}

@media only screen and (max-width: 414px) {
    
    .menuText {
        font-size: smaller;
    }


    .homeSplash {
        font-size: 1.75rem;
        position: static;
        left: 0;
        right: 0;
        float: none; 
    }

    .homeSplashSubtitle {
        font-size: 1.25rem;
        position: static;
        float: none; 
    }

    .homeVersionNumber {
        position: static;
        clear: both;
        float: none;
        margin-bottom: 30px;
    }

    .navbar .freebooter {
        font-family: DreamMMA !important;
        font-size: 1.5rem !important;
        padding: 0;
        margin: 0;
        vertical-align: middle;
    }

    .tryDesigner {
        right: 0px;  
    }

    .tryDesigner2 {
        right: 0;
    }

    .levelUpImg {
        width: 75%;
    } 

    div.homeNote {
        margin-top: 70px;
    }  

    .smallClassAndLevel {
        display: inline-block;
    }
    
}






